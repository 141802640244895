/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.blank_bg {
    background: none !important;
    color: var(--ion-color-primary) !important;
}

.candidate_name {
    text-transform: capitalize;
}

.editor-inactive {
    .ck.ck-sticky-panel__content,
    ion-item {
      display: none;
    }
}

ckeditor {
    width: 100%;
    margin-top: 15px;
    display: block;
    padding: 0 5px;
  }
  
  .ckeditor-title {
    border: none;
    margin-bottom: -15px;
  }
  
.is-suspicious {
  border: 2px solid red;
  background: red;
  color: black;
}
